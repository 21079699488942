.clt-tube-border {
  width: 100%;
  height: 100%;
  background-image: url('../assets/images/cadre-2-min.png');
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

@media screen and (max-width: 480px), screen and (max-height: 480px) {
  .clt-tube-border {
    background-image: url('../assets/images/cadreMobile-2-min.png');
  }
}

@font-face {
  font-family: 'Megapunch';
  src: url('../assets/fonts/Megapunch_Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'PressStart';
  src: url('../assets/fonts/pressStart.ttf') format('truetype');
}