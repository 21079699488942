@media screen and (max-width: 480px),
screen and (max-height: 480px) {
  body {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  .game {
    width: 100vw;
    height: 100vh;
  }

  .hero {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (min-width: 481px),
screen and (min-height: 481px) {
  body {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  .game {
    width: 100vw;
    height: 100vh;
  }

  .hero {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (max-width: 600px) {

  /* Styles for mobile devices */
  #hero {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full height */
    width: 100vw;
    /* Full width */
  }
}

@media only screen and (max-width: 600px) {
  .road {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }
}

/* UI */
.topUI {
  position: absolute;
  z-index: 1000;
  /* need this cause clip-path changes stack context */
  transform: translate(-50%, 25px);
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
  letter-spacing: 2px;
  color: #fff;
  font-size: 17px;
}

.topUI::before {
  display: inline-block;
  height: 22px;
  padding: 1px 2px;
  line-height: 19px;
  font-size: 17px;
  background: whitesmoke;
  text-shadow: none;
  font-weight: 900;
  letter-spacing: 0;
  border-radius: 6px;
  margin-right: 30px;
  border: 2px solid red;
}

#time {
  left: 25%;
  color: white;
}

#time::before {
  content: "TIME LEFT";
  color: black;
}

#lap {
  left: 75%;
  width: 45%;
}

#lap::before {
  content: "LAP";
  color: black;
}

#tacho {
  position: absolute;
  text-align: right;
  width: 23%;
  bottom: 5%;
  z-index: 2000;
  color: #e62e13;
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
  letter-spacing: 2px;
  font-size: 23px;
}

#tacho::after {
  content: "km/h";
  color: #fab453;
  font-size: 18px;
  margin-left: 5px;
}

/*
road
*/
#game {
  position: relative;
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #222;
  transition: opacity 10s;
}

#road {
  transition: opacity 2s;
  transition-timing-function: steps(8, end);
}

#road * {
  position: absolute;
  image-rendering: pixelated;
}

#hero {
  background-repeat: no-repeat;
  background-position: -110px 0;
  z-index: 2000;
  transform: scale(1.4);
}

#cloud {
  background-size: auto 100%;
  width: 100%;
  height: 57%;
  overflow: hidden;
}

/*
home
*/
#road {
  position: absolute;
  width: 100%;
  height: 100%;
}

#home {
  position: absolute;
  color: #fff;
  width: 100%;
  height: 100%;

  z-index: 1000;
  /* need this cause clip-path changes stack context */
}

#highscore {
  position: absolute;
  width: 100%;
  height: 20%;
  bottom: 0;
  column-count: 3;
  column-fill: auto;
}

#highscore * {
  color: red;
  margin: 0 0 6px 27px;
}

#text {
  position: absolute;
  left: 50%;
  top: 70%;
  transform: translate(-50%, -50%);
  font-size: 1.2em;
  color: red;
  text-shadow: 0 0 black, 0 2px black, 2px 0 black, 0 0 black;
}

.blink {
  animation: blinker 1.5s steps(14, end) infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

#spinning-logo {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 25vh;
  width: auto;
}