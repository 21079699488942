.clt-tube-border {
  width: 100%;
  background-image: url('../assets/images/cadre-2-min.png');
  background-size: 100% 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 10;
}
@media screen and (max-width: 480px), screen and (max-height: 480px) {
  .clt-tube-border {
    background-image: url('../assets/images/cadreMobile-2-min.png');
  }
}
.arcade-textfield {
  position: absolute;
  background-color: black;
  color: white;
  border: 2px solid white;
  font-family: 'PressStart';
  width: 70%;
  height: auto;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Add more styles as needed */
}