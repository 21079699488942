.App {
  width: 100vw;
  height: 100vh;
  /* background-color: black; */
  background-color: #E1E1E1;
  overflow: hidden;
}
body {
  background: #E1E1E1;
  height: 98vh;
}