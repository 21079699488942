.clt-tube-border {
  width: 100%;
  background-image: url('../assets/images/cadre-2-min.png');
  background-size: 100% 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 10;
}
@media screen and (max-width: 480px), screen and (max-height: 480px) {
  .clt-tube-border {
    background-image: url('../assets/images/cadreMobile-2-min.png');
  }
}

.clt-copyright {
  bottom: 10vh;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
}


@font-face {
  font-family: 'Megapunch';
  src: url('../assets/fonts/Megapunch_Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'PressStart';
  src: url('../assets/fonts/pressStart.ttf') format('truetype');
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}

.blinking {
  animation: blinker 2s linear infinite;
}

.spinning-gif {
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  height: 20vh;
  width: auto;
}